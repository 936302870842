import { TourStepContent } from "@coach-mark/components/TourStepContent";
import { Text } from "@gada-saas/web-ui";
import { PopoverContentProps } from "@reactour/tour";
import * as React from "react";
import router from "next/router";
import { Pages } from "@common/constants";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";

const AddInventoryIntroTutorialStep: React.VFC<PopoverContentProps> = (
  props
) => {
  const analytics = useSegmentAnalytics();

  return (
    <TourStepContent
      title="Yuk Mulai Dengan Menambahkan
      Barang Favorit Toko Anda"
      description={
        <Text variant="body2" ink="neutralLight">
          Setelah menambahkan barang, Anda bisa menggunakan semua fitur-fitur
          lain di GudangAda Solusi.
        </Text>
      }
      primaryButtonText="Buka Daftar Barang"
      customPrimaryButtonClick={() => {
        analytics.track("Homepage Coachmark Clicked", {
          coachmark_name: "homepage add inventory",
          action_text: "Buka Daftar Barang",
          has_inventory: false,
          has_transactions: false,
          step_Sequence: 0,
        });

        router.push(Pages.INVENTORY);
      }}
      customDismissButtonClick={() => {
        analytics.track("Homepage Coachmark Dismissed", {
          coachmark_name: "homepage add inventory",
          action_text: "Buka Daftar Barang",
          has_inventory: false,
          has_transactions: false,
          step_Sequence: 0,
        });
      }}
      onComponentRendered={() => {
        analytics.track("Homepage Coachmark Shown", {
          coachmark_name: "homepage add inventory",
          action_text: "Buka Daftar Barang",
          has_inventory: false,
          has_transactions: false,
          step_Sequence: 0,
        });
      }}
      {...props}
    />
  );
};

export default AddInventoryIntroTutorialStep;
